@import '@xq/ui-kit/lib/styles/colors.scss';

.password {
  &-list {
    margin-block-start: 0;
    margin-block-end: 40px;
    padding-inline-start: 0;
    padding-left: 20px;
  }

  &-item {
    color: $gray-40;

    &--valid {
      color: $green-70;
    }
  }
}

.header {
  margin-bottom: 40px;
  text-align: center;
}
