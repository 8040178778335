@import '@componentsStyles/colors';
@import '@componentsStyles/breakpoints';
@import '@componentsStyles/variables';
@import '@componentsStyles/zIndex';
@import '@componentsStyles/elevation';
@import '../../Header.module.scss';

.wrapper {
  @include elevation(menu);
  display: flex;
  background: $white-0;
  border-radius: 0 0 $border-radius $border-radius;
  width: -moz-max-content;
  width: max-content;
  z-index: $dropdown-zIndex;
  position: absolute;
}

.name {
  margin-left: 8px;
  margin-right: 2px;
}

.flex {
  display: flex;
  align-items: center;
}

.link {
  @extend .link;
  display: block;
  margin: 40px;
}
