@import '@componentsStyles/colors.scss';

.modal {
  width: 415px;
}

.password {
  &-list {
    margin-block-start: 0;
    margin-block-end: 40px;
    padding-inline-start: 0;
    padding-left: 20px;
  }

  &-item {
    color: $gray-40;

    &--valid {
      color: $green-70;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
}