@import '@xq/ui-kit/lib/styles/breakpoints';
.layout {
  height: 100vh;
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-template-columns: 40px 6fr 40px;

  @media (min-width: $sm) {
    grid-template-columns: 3fr 6fr 3fr;
  }
  @media (min-width: $md) {
    grid-template-rows: none;
    grid-template-columns: 7fr 1fr 3fr 1fr;
  }
}

.block {
  width: 100%;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-row: 1;
  grid-column: 2/3;
  padding: 20px 0;
  @media (min-width: $md) {
    grid-row: 1;
    grid-column: 3 / 4;
    height: 100vh;
  }
}

.image {
  height: calc(34vh - 20px);
  grid-row: 2;
  grid-column: 1/4;

  @media (min-width: $sm) {
    height: 34vh;
  }

  @media (min-width: $md) {
    height: 100vh;
    grid-row: 1;
    grid-column: 1/2;
  }
}

.dropdown {
  position: fixed;
  right: 22px;
  top: 60vh;
  @media (min-width: $md) {
    top: initial;
    bottom: 60px;
  }
}
