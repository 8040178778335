@import '@xq/ui-kit/lib/style.css';

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
