@import '@componentsStyles/colors';
@import '@componentsStyles/variables';

.file {
  align-items: center;
  margin-top: 16px;
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &-end {
      &::first-letter {
        text-transform: none;
      }
    }
  }

  &-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--disabled {
      @extend .file-icon;
      cursor: initial;
    }
  }

  &-block {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);

    &-error {
      @extend .file-block;
      width: calc(100% - 133px);
    }
  }

  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 28px);
  }
}
