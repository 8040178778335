@import '@componentsStyles/colors';
@import '@componentsStyles/breakpoints';
@import '@componentsStyles/variables';
@import '@componentsStyles/zIndex';
@import '@componentsStyles/elevation';

.wrapper {
  position: relative;
}

.switcher {
  display: flex;
  align-items: center;
  transition: $transition-duration $transition-timing-function;
  &:hover {
    cursor: pointer;
    svg {
      fill: $gray-100;
    }
  }
  &:active {
    filter: grayscale(1);
  }

  &--disabled {
    &:hover {
      cursor: default;
    }
    &:active {
      filter: none;
    }
  }
}

.icon {
  margin-right: 20px;

  svg {
    transition: $transition-duration $transition-timing-function;
    fill: $gray-60;
  }

  &--disabled {
    display: none;
  }

  &--active {
    @extend .icon;
    svg {
      fill: $gray-100;
    }
  }
}

.dropdown {
  @include elevation(menu);
  position: absolute;
  left: 0px;
  top: 30px;
  z-index: $dropdown-zIndex;
  padding: 20px 40px;
  gap: 20px;
  background: $white-0;
  border-radius: 0px 0px $border-radius $border-radius;
  margin-top: 5px;

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
  }
}

.indicator {
  position: absolute;
  left: -18px;
}
