@import '@xq/ui-kit/lib/styles/colors.scss';

.header {
  margin-bottom: 40px;
  text-align: center;
}

.block-button {
  width: 100%;
}

.divider {
  margin: 0;
  border: 0;
  border-bottom: 1px solid $gray-20;
}

.continue-text {
  margin: 0 10px;
  background: $white-0;
  text-align: center;
  color: $gray-60;
  padding: 0 10px;
}

.continue-block {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  width: 100%;
}

.continue-wrap {
  position: relative;
  margin-top: 40px;
}
